<template>
  <v-app >
    <cabecera/>
    <v-main style="background: #eef5f9"
    >
      <v-container    >
        <v-row class="text-center">
          <v-col cols="12">
            <section id="eventos">
              <v-row  class="text-center">
                <v-col cols="12" sm="12" md="12" lg="12">
                  <h1 class="display-1 font-weight-bold">Mis Tickets</h1>
                </v-col>
              </v-row>

              <v-row justify="center" >

                <v-col cols="12" lg="3" sm="3" v-for="ticket in tickets" :key="ticket.id" >
                    <v-card  elevation="10">
                      <v-card-title  justify="center" ><h2 >{{ticket.eventoEntrada.eventoEntradaZonaEvento.zonaEventoEvento.evento}}</h2></v-card-title>
                      <v-card-text >
                        <v-row >
                          <v-col cols="12" lg="6" md="6" sm="6">
                            <p>Zona: {{ticket.eventoEntrada.eventoEntradaZonaEvento.zonaEvento}}</p>
                            <p>Entrada: {{ticket.eventoEntrada.eventoEntrada}}</p>
                          </v-col>
                          <v-col v-if="ticket.estadoEvento.estadoEventoId ===2 || ticket.estadoEvento.estadoEventoId === 4" cols="12" lg="6" md="6" sm="6">
                            <p><v-btn class="primary" @click="verentrada(ticket)"><v-icon left>
                              fas fa-qrcode
                            </v-icon>Ver Entrada</v-btn></p>
                            <p><v-btn class="secondary" @click="verwallet(ticket)"><v-icon left>
                              fas fa-wallet
                            </v-icon>Ver Wallet</v-btn></p>
                            <p v-if="!ticket.transferido">
                              <v-btn class="warning" @click="transferencia(item)"><v-icon left>
                                fas fa-exchange-alt
                              </v-icon>Transferir</v-btn>
                            </p>
                          </v-col>
                          <v-col v-if="ticket.estadoEvento.estadoEventoId ===1" cols="12" lg="6" md="6" sm="6">
                            <p>PENDIENTE DE APROBACIÓN</p>
                          </v-col>
                          <v-col v-if="ticket.estadoEvento.estadoEventoId ===3" cols="12" lg="6" md="6" sm="6">
                            <p>RECHAZADO</p>
                          </v-col>
                        </v-row>

                      </v-card-text>
                    </v-card>
                </v-col>
              </v-row>
            </section>


          </v-col>
        </v-row>
      </v-container></v-main>
    <piepagina/>
    <v-overlay :value="overlay">
      <v-img
          src="@/assets/loading.gif"

      />
    </v-overlay>
    <transferencia />
  </v-app>
</template>

<script>

import cabecera from './general/cabecera'
import piepagina from './general/piepagina';
import moment from "moment-timezone";
import {mapGetters} from "vuex";
import axios from "axios";
import store from "@/store";
import Transferencia from "@/components/modals/transferencia";

export default {
  name: 'ordenes',
  components: {
    Transferencia,
    cabecera,
    piepagina,

  },
  data ()  {
    return {
      tickets:[],
      overlay:false,
    }
  },
  mounted() {
    if(this.usuario.id>0){
      this.dameOrdenes()
    }else{
      this.$router.push('/');
    }
  },
  methods:{
    formatDatee(value) {
      return moment(value).locale('es-pe').format("DD MMMM YYYY")
    },
    dameOrdenes(){
      this.overlay = true
      var bodyFormData = new FormData();
      bodyFormData.append('personaId',  this.usuario.id);
      bodyFormData.append('casc',  6);
    axios
      .post('tickets/clienteeventos',bodyFormData)
        .then(response => {
          this.overlay = false;
          this.tickets = response.data
        })
        .catch(error => {
          this.overlay = false;
          console.error(error);
        });
    },
    verentrada(ticket){
      store.dispatch("fetchTicket",ticket)
      this.$router.push({ path: '/entrada' });
    },
    transferencia(ticket){
      store.dispatch("fetchTicket",ticket)
      store.dispatch("showTransferencia")

    },
    verwallet(ticket){
      axios({
             method: 'get',
            url: 'passkit/'+ticket.id,
           responseType: 'arraybuffer'
      })
          .then(response=>{
            const reader = new FileReader();
            const out = new Blob([response.data], {
              type: "application/vnd.apple.pkpass",
            });
            reader.onload = function () {
              if (reader.result !== null) {
                window.location.href = reader.result.toString();
              }
            };
            reader.readAsDataURL(out);
          })
          .catch(() => console.log('error occured'))
    },
    forceFileDownload(response){
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'pass.pkpass') //or any other extension
      document.body.appendChild(link)
      link.click()
    }

  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      usuario: "usuario"
    })
  }
}
</script>
