<template>
  <v-dialog
      v-model="showTransferencia"
      persistent
      width="unset"
  >
    <v-card>
      <v-card-title>Transferencia</v-card-title>
      <v-card-subtitle>Para Transferir es necesario que el Usuario a Transferir tenga una cuenta con Tunay. </v-card-subtitle>
      <v-card-text>
        <v-row>
          <v-col cols="10" lg="10" md="10" sm="10">
            <v-text-field
                v-model="email"
                label="Email"
                type="email"
                required
            ></v-text-field>
            <p v-if="noemail" style="color: red;">Este email NO existe en nuestro sistema, no sera posible transferirle una entrada</p>
            <p v-if="trans" style="color: blue">Es posible transferir a {{atrasnferir.nombre}}</p>
          </v-col>
          <v-col cols="1" lg="1" md="1" sm="1">
            <v-btn class="warning" @click="comprobarEmail"><v-icon>fas fa-check</v-icon></v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="6" lg="6" md="6" sm="6">
<v-btn class="error" @click="cerrar">Cerrar</v-btn>
          </v-col>
          <v-col cols="6" lg="6" md="6" sm="6">
            <v-btn class="primary" :disabled="disabled">Transferir</v-btn>

          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-overlay :value="overlay">
      <v-img
          src="@/assets/loading.gif"

      />
    </v-overlay>
  </v-dialog>

</template>
<script>
import {mapGetters} from "vuex";
import axios from "axios";
import store from "@/store";

export default {
  name: 'transferencia',
  data: () => ({
    overlay:false,
    email:'',
    noemail: false,
    trans: false,
    disabled: true,
    atrasnferir:{}
  }),
  methods:{
    cerrar(){
      store.dispatch("hideTransferencia")
    },
    comprobarEmail(){
      this.overlay = true
      var bodyFormData = new FormData();
      bodyFormData.append("email",this.email);
      axios
          .post('personas/email',bodyFormData)
          .then(response => {
            this.atrasnferir = response.data
            if(this.atrasnferir.id>0){
              this.noemail = false
              this.disabled = false
              this.trans = true
            }else{
              this.noemail = true
              this.disabled = true
              this.trans = false
            }
            this.overlay = false
          })
          .catch((error)  => {
            this.overlay = false
            console.error(error);
          });
    },
    transferir(){
      this.overlay = true
      var bodyFormData = new FormData();
      bodyFormData.append("data",this.ticket);
      bodyFormData.append("personaId",this.atrasnferir.id);
      axios
      .post('tickets/transferir',bodyFormData)
          .then(response => {
            let tick = response.data;
            if(tick.persona.id === this.atrasnferir.id){
              this.$swal({
                icon: 'success',
                title: 'Éxito',
                text: 'La transferencia se realizó con éxito',
              });
            }else{
              this.$swal({
                icon: 'error',
                title: 'Oops...',
                text: 'Hubo un error intentelo de nuevo!',
              });
            }
            this.overlay = false
          })
          .catch((error)  => {
            console.error(error);
          });
    }
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      usuario: "usuario",
      ticket: "ticket",
      showTransferencia: "showTransferencia"
    })
  }
}
</script>